.toolbar-background {
  background-color: var(--ion-color-primary) !important;
}

.custom_title {
  color: var(--ion-color-primary-contrast);
}

.custom_link {
  color: var(--ion-color-primary-tint) !important;
}
