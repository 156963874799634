.MuiListItemIcon-root {
  color: var(--ion-color-primary-contrast) !important;
}
.menu_footer {
  position: absolute;
  bottom: 0;
}
.menu_footer_title {
  font-size: small;
}
