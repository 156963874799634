/* Toast */
.Toastify__toast-container {
}
.Toastify__toast--default {
  background: var(--ion-color-primary-contrast);
  color: var(--ion-color-primary);
}
.Toastify__toast--info {
  background: var(--ion-color-info);
}
.Toastify__toast--success {
  background: var(--ion-color-success);
}
.Toastify__toast--warning {
  background: var(--ion-color-warning);
}
.Toastify__toast--error {
  background: var(--ion-color-danger);
}

/* swal */
.swal-overlay {
  background-color: rgba(var(--ion-color-primary-background-rgba));
}

.swal-modal {
  background-color: var(--ion-color-primary-contrast);
}

.swal-title {
  color: var(--ion-color-primary-text);
}

.swal-text {
  color: var(--ion-color-primary-text);
}

.swal-button {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.swal-footer {
  text-align: center;
}

.swal-icon--success:before {
  background-color: var(--ion-color-primary-contrast);
}

.swal-icon--success:after {
  background-color: var(--ion-color-primary-contrast);
}

.swal-icon--success__hide-corners {
  background-color: var(--ion-color-primary-contrast);
}

.ion-page {
  background-color: var(--ion-color-primary-contrast);
  /* background: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,.9)), url(./assets/logo.png); */
  background-image: url(./assets/watermark.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  color: var(--ion-color-primary-text);
}

/* .MuiGrid-item {
  background-color: var(--ion-color-primary-contrast);
  color: var(--ion-color-primary);
} */

.MuiGrid-item {
  padding: 3px !important;
}

.MuiFormControl-marginNormal {
  margin-top: 3px !important;
  background: var(--ion-color-primary-white) !important;
  border-radius: 5px;
}

.MuiInputLabel-outlined {
  z-index: 0 !important;
}

.MuiInputLabel-asterisk {
  color: 'red'
}

.MuiContainer-root {
  max-width: none !important;
  margin: 0px !important;
}

/* Input Text */
.MuiOutlinedInput-inputMarginDense{
  color: var(--ion-color-primary-text) !important;
}

/* Select option */
.css-1pahdxg-control {
  border-color: var(--ion-color-primary) !important;
  box-shadow: 0 0 0 1px var(--ion-color-primary) !important;
}

.css-1rhbuit-multiValue{
  background-color: var(--ion-color-primary) !important;
  color: var(--ion-color-primary-white) !important;
}

.css-yk16xz-control {
  border-color: var(--ion-color-primary) !important;
}

.css-tlfecz-indicatorContainer {
  color: var(--ion-color-primary-text) !important;
}

.css-1n7v3ny-option{
  color: var(--ion-color-primary-text) !important;
  background-color: var(--ion-color-primary-background) !important;
}

.css-9gakcf-option {
  background-color: var(--ion-color-primary) !important;
  color: var(--ion-color-primary-contrast) !important;
}

.css-yt9ioa-option{
  color: var(--ion-color-primary-black) !important;
}
.css-4ljt47-MenuList {
  max-height: 150px !important;
  overflow-y: scroll !important;
}

#scrollable-auto-tabpanel-0{
  width: 100%;
}

#scrollable-auto-tabpanel-1{
  width: 100%;
}

#scrollable-auto-tabpanel-2{
  width: 100%;
}

#scrollable-auto-tabpanel-3{
  width: 100%;
}

#scrollable-auto-tabpanel-4{
  width: 100%;
}

.css-12jo7m5{
  color: var(--ion-color-primary-white) !important;
  font-weight: 600 !important;
}

/* Date picker */
.react-date-picker {
  height: 38px;
  border-radius: 5px;
  border: 1px solid var(--ion-color-primary) !important;
  width: 100%;
}

.react-date-picker:hover {
  border: 2px solid var(--ion-color-primary) !important;
}

.react-date-picker:focus {
  border: 2px solid var(--ion-color-primary) !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ion-color-secondary); 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-danger); 
}

.MuiTab-textColorPrimary.Mui-selected{
  background: var(--ion-color-primary-tint) !important;
  color: var(--ion-color-secondary) !important;
  font-weight: 800;
}

.MuiTabs-indicator{
  background: var(--ion-color-primary) !important;
}

.MuiTab-textColorPrimary{
  color: var(--ion-color-primary-contrast) !important;
}

.MuiButton-fullWidth{
  margin: 12px 0px 12px;
}

.MuiDataGrid-footerContainer{
  background: var(--ion-color-primary) !important;
}

.MuiIconButton-colorInherit{
  color: var(--ion-color-primary-contrast) !important;
}

.MuiDataGrid-selectedRowCount{
  color: var(--ion-color-primary-contrast) !important;
}

.MuiTablePagination-caption{
  color: var(--ion-color-primary-contrast) !important;
}

.MuiDataGrid-root{
  color: var(--ion-color-primary-text) !important;
}

.MuiAvatar-img {
  object-fit: scale-down !important;
  padding: 10px !important;
}

.MuiTab-root{
  min-width: auto !important;
  min-height: auto !important;
  font-size: 0.7rem !important;
}

.MuiTabs-root{
  height: 30px !important;
  min-height: auto !important;
}

/* slides */
ion-slides {
  width: 100%;
  height: max-content;
  max-height: 400px;
}

.css-1ri6ub7-MuiPaper-root-MuiCard-root{
  margin-left: auto !important;
  margin-right: auto !important;
}

ion-badge > a {
  color: var(--ion-color-danger) !important
}

a{
  cursor: pointer;
  color: var(--ion-color-secondary)
}

ion-badge{
  cursor: pointer;
}

.MuiBox-root{
  padding: 0px !important;
}

.react-viewer-icon{
  padding: 6px;
}

.welcome-gif{
  max-width: 1600px;
}

.welcome-gif > h3 {
  display: none !important;
}

.swiper-pagination{
  display: none;
}

#txtmeLivechatWrapper{
  bottom: 0px !important;
}

#txtmeLivechatTitle{
  margin-bottom: 10px !important;
}

/* File */
.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Upload Photo';
  color: var(--ion-color-primary-contrast);;
  display: inline-block;
  background: var(--ion-color-primary);
  border-radius: 4px;
  padding: 7px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  height: 36px;
  margin-top: 4px;
  font-size: 14px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}
.css-1ee5err-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
  color: var(--ion-color-primary) !important;
}

ion-list{
  width: 100%;
}

.css-jcn4dz-MuiBadge-badge{
  background-color: var(--ion-color-secondary) !important;
}